
.role-create-main-div{
  width: 60%;
  margin-left: 20%;
  background-color: white;
  margin-bottom: 50px; box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12); 
  margin-top: 20px;
}

.role-create-form-main-div{
  margin-left: 1%; 
  margin-top: -20px;
}
.role-create-form-main-header{
  font-size: 24px; font-weight: 500; margin-top: 0px;
}

.role-create-message-success{
  position: fixed;
  bottom: 10px;
  background-color: #3b4156;
  animation: cssAnimation 0s 1s forwards;
  padding: 10px;
  width: 25%;
  text-align: center;
  color: white;
}
.role-create-validation-message{
  color: red;
  padding-left: 0px
}

#role-create-autocomplete-left-dropdown { 
  float:left;  
  width:40%; 
} 

#role-create-autocomplete-right-dropdown{ 
  float:left;  
  width:45%; 
  margin-left: 10%;
} 

.role-create-header2{
  font-size: 18px; font-weight: 500; margin-left: 0px; text-align: left; padding: 0px;
}

.role-create-header3{
  font-size: 18px; font-weight: 500; margin-top: 0px;
}

.numericTextBoxrole1 { 
  /* float:left; */
  margin-left: 110px;  
  width:60%; 
} 

.numericTextBoxrole2 { 
  float:right;  
  width:10%; 
  margin-left: 100px;
} 
.numericTextBoxrole3 { 
  /* float:left; */
  margin-left: 100px;  
  width:60%; 
} 

.numericTextBoxrole4 { 
  float:right;  
  width:10%; 
  margin-left: 100px;
} 
.numericTextBoxrole5 { 
  /* float:left; */
  margin-left: 59px;  
  width:60%; 
} 

.numericTextBoxrole6 { 
  float:right;  
  width:10%; 
  margin-left: 100px;
} 
.numericTextBoxrole7 { 
  /* float:left; */
  margin-left: 102px;  
  width:60%; 
} 

.numericTextBoxrole8 { 
  float:right;  
  width:10%; 
  margin-left: 100px;
} 
.numericTextBoxrole9 { 
  /* float:left; */
  margin-left: 88px;  
  width:60%; 
} 

.numericTextBoxrole10 { 
  float:right;  
  width:10%; 
  margin-left: 100px;
} 
.numericTextBoxrole11 { 
  /* float:left; */
  margin-left: 112px;  
  width:60%; 
} 

.numericTextBoxrole12 { 
  float:right;  
  width:10%; 
  margin-left: 100px;
} 
#checkbox1 { 
  float:left;  
  width:35%; 
} 
#checkbox2 { 
  float:left;  
  width:27%; 
 
} 
#checkbox3 { 
  float:left;  
  width:42%; 
  margin-left: -35px;
} 

.Profilebutton2{
  margin-left: 160px;
  margin-right: 20px;
  padding-left: 25px;
  padding-right: 25px;
  
}