.profile-main-div{
    width: 65%;
    margin-left: 20%;
    background-color:white;
    margin-bottom: 50px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
    margin-top: 20px;
}


.h1style{
    font-size: 1.5rem;
    font-weight: 100;
    line-height: 1;
}
.h3style{
    font-size: 1.25rem;
    margin-top: 40px;
}
.h3style1{
    font-size: 1.25rem;
    float: left;
    width: 100%
}
#profile-checkbox { 
    float:left;  
    width:0%; 
}
#profile-autocomplete-left-dropdown { 
    float:left;  
    width:40%; 
  } 
  
  #profile-autocomplete-right-dropdown{ 
    float:left;  
    width:45%; 
    margin-left: 10%;
  } 
  
  .company-section{
      margin-left: 22px;
  }
  .company-inner-section{
      margin-bottom: 20px;
  }
  #pcheckbox { 
    float:left;  
    width:30%; 
  } 