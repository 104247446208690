.k-panelbar > .k-item > .k-link:hover, .k-panelbar > .k-item > .k-link.k-state-hover, .k-panelbar > .k-item > .k-link.k-state-focused {
  background-color: #0b2638;
  color: white;
}

.k-panelbar > .k-item > .k-link.k-state-selected {
  color: white;
  background-color: #0b2638;
}

.k-panelbar {
  color: white;
  background-color: #3b4156;
}

.k-panelbar .k-group > .k-item > .k-link.k-state-selected {
  color: white;
  background-color: #0b2638;
}

.k-panelbar .k-group > .k-item > .k-link:hover, .k-panelbar .k-group > .k-item > .k-link.k-state-hover, .k-panelbar .k-group > .k-item > .k-link.k-state-focused {
  background-color: #0b2638;
}


.left-menu {
  overflow: hidden;
  background-color: #58628F;
  height: 63px;
  width:100%;
  padding: 1px;
}
.apollo-image{
  margin-left: .25em;
}
.apollo-image img{
  margin-top: .25em;
}

.left-menu-sub-items {
  margin-left: .90em;
}

.reorder-icon{
  font-size: 27px;
  margin-left: 5px;
  color: #999;
}
.reorder-icon:hover{
  font-size: 27px;
  margin-left: 5px;
  color: #75AEF1;
}