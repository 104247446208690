.k-overlay { 
  background-color: #fff;
  opacity: 0;
}

.k-icon.k-i-x {
  visibility: hidden;
}

.k-menu-group .k-item>.k-link, .k-menu-vertical .k-item>.k-link {
  padding: 4px 20px !important;
  line-height: 0% !important;
}