.dashboard-container{
    padding: 3em 5em 0 5em;
}
.dashboard-left{
   
    background-color:#fff;
}
.fixed-dashboards{
    padding:2em;
    border-bottom: 1px solid #d9d9d9;
}
.fixed-rateMyOffer-dashboards{
  padding:1em;
  border-bottom: 1px solid #d9d9d9;
}
.dashboard-right{
  top:-0.8px;
    background-color: #3b4156;
    box-shadow: inset 0 0 8px -3px #000;
}
.dasbhoard-caption{
    padding-bottom: 1em;
    font-size: 16px;
}
.dashboard-input-wrapper{
    padding:2em;
}
.TabstripCss .k-tabstrip-items .k-link {
  padding: 16px 101.6px;
  color: white;
  background-color: #3b3f4f;
}

.tabstrip-div-center{
    color:white; 
    font-size:30px;
    padding-left:20px;
}
                
.dashboard-icon-save{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 20;
    background-clip:  padding-box;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
    background-color: #58628f;
    float: right;
    margin-right: 15px;
  }

  .dashboard-usericon-save{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 20;
    background-clip:  padding-box;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
    background-color: #58628f;
    float: left;
    margin-right: 15px;
  }
  .power_user_dashboard-icon-save{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 20;
    background-clip:  padding-box;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
    background-color: #58628f;
    float: right;
    margin-right: 15px;
  }
  .dashboard-input-box{
    width: 65px;
    /* margin-left: 0%; */
  }
  .dashboard-input-box .k-numeric-wrap {
    border-color: #3b4156;
    color: white;
    font-size: 24px;
  }
  .dashboard-input-box .k-numeric-wrap .k-input {
    color: white;
    border-color: white;
    padding-left: 0px;
    font-size: 24px;
    text-align: center;
  }
  .dashboard-input-box .k-numeric-wrap.k-state-invalid{
    color: None;
    border: 0px;
  }
  .data-input-cash-box{
    width:120px;
     margin-left: 0%; 
  }
  .data-input-cash-box .k-numeric-wrap {
    border-color: #3b4156;
    color: white;
    font-size: 24px;
    text-align: center
  }
  .data-input-cash-box .k-input {
    text-overflow: unset;
  }
  .data-input-cash-box .k-numeric-wrap .k-input {
    color: white;
  }
  .data-input-cash-box .k-numeric-wrap.k-state-invalid{
    color: None;
    border: 0px;
  }
  .data-input-cash-box.k-numerictextbox .k-input {
    text-align: center;
}

.input-wrapper-lock-dashboard {
    display:inline-block;
    position: relative;
    width: 100%;
    margin-left: 6px;
  }
  .input-wrapper-lock-dashboard:after {
    font-family: 'FontAwesome';
    content: '\f023';   /* close lock '\f13e' */
    position: absolute;
    color: #9dacdd;
    left: 0px;
    top:1px;
  }
  
  .input-wrapper-unlock-dashboard {
    display:inline-block;
    position: relative;
    margin-left: 6px;
  }
  .input-wrapper-unlock-dashboard:after {
    font-family: 'FontAwesome';
    content: '\f13e';   
    position: absolute;
    color: #9dacdd;
    left: 0px;
    top:1px;
  }
  
  .input-wrapper-lock-bonus-equity-dashboard {
    display:inline-block;
    position: relative;
    margin-left: 0px;
  }
  .input-wrapper-lock-bonus-equity-dashboard:after {
    font-family: 'FontAwesome';
    content: '\f023';   /* close lock '\f13e' */
    position: absolute;
    color: #9dacdd;
    left: 0px;
    top:1px;
  }
  
  .input-wrapper-unlock-bonus-equity-dashboard {
    display:inline-block;
    position: relative;
    margin-left: 0px;
  }
  .input-wrapper-unlock-bonus-equity-dashboard:after {
    font-family: 'FontAwesome';
    content: '\f13e';   /* close lock '\f13e' */
    position: absolute;
    color: #9dacdd;
    left: 0px;
    top:1px;
  }
  
  .input-wrapper-lock-cash-base-equity-dashboard {
    display:inline-block;
    position: relative;
    margin-left: 0px;
  }
  .input-wrapper-lock-cash-base-equity-dashboard:after {
    font-family: 'FontAwesome';
    content: '\f023';   /* close lock '\f13e' */
    position: absolute;
    color: #9dacdd;
    left: 6px;
    top:1px;
  }
  
  .input-wrapper-unlock-cash-base-equity-dashboard {
    display:inline-block;
    position: relative;
    margin-left: 0px;
  }
  .input-wrapper-unlock-cash-base-equity-dashboard:after {
    font-family: 'FontAwesome';
    content: '\f13e';   /* close lock '\f13e' */
    position: absolute;
    color: #9dacdd;
    left: 6px;
    top:1px;
  }
  
  .input-wrapper-lock-cash-bonus-equity-dashboard {
    display:inline-block;
    position: relative;
    margin-left: 0px;
  }
  .input-wrapper-lock-cash-bonus-equity-dashboard:after {
    font-family: 'FontAwesome';
    content: '\f023';   /* close lock '\f13e' */
    position: absolute;
    color: #9dacdd;
    left: 0px;
    top:1px;
  }
  
  .input-wrapper-unlock-cash-bonus-equity-dashboard {
    display:inline-block;
    position: relative;
    margin-left: 0px;
  }
  .input-wrapper-unlock-cash-bonus-equity-dashboard:after {
    font-family: 'FontAwesome';
    content: '\f13e';   /* close lock '\f13e' */
    position: absolute;
    color: #9dacdd;
    left: 0px;
    top:1px;
  }
  
  .input-wrapper-lock-equity-annualvalue-dashboard {
    display:inline-block;
    position: relative;
    margin-left: 0px;
  }
  .input-wrapper-lock-equity-annualvalue-dashboard:after {
    font-family: 'FontAwesome';
    content: '\f023';   /*close lock '\f13e'*/
    position: absolute;
    color: #9dacdd;
    left: 0px;
    top:0px;
  }
  
  .input-wrapper-unlock-equity-annualvalue-dashboard {
    display:inline-block;
    position: relative;
    margin-left: 0px;
  }
  .input-wrapper-unlock-equity-annualvalue-dashboard:after {
    font-family: 'FontAwesome';
    content: '\f13e';   /*close lock '\f13e'*/
    position: absolute;
    color: #9dacdd;
    left: 0px;
    top:0px;
  }

  .data-input-annaul-field .k-numeric-wrap {
    border-color: #3b4156;
    color: white;
    font-size: 24px;
    
  }
  .data-input-annaul-field .k-numeric-wrap .k-input {
    color: white;
    text-align: center;
  }
  .data-input-annaul-field .k-numeric-wrap.k-state-invalid{
    color: None;
    border: 0px;
  }

  .data-input-percent-box{
    width:100px;
  }
  
  .data-input-percent-box .k-numeric-wrap {
    border-color: #3b4156;
    color: white;
    font-size: 24px;
  }
  
  .data-input-percent-box .k-numeric-wrap .k-input {
    color: white;
  }
  .data-input-percent-box .k-numeric-wrap.k-state-invalid{
    color: None;
    border: 0px;
  }
  .rate-offer-div-box{
    color: white;
    font-size: 14px;
    padding: 10px;
  }
  .inputDotClass .k-input{
    text-overflow: unset;
    font-weight: 500;
    color: rgba(0,0,0,0.87);
  }

  .dropdownDotClass .k-input{
    text-overflow: unset;
    font-weight: 500;
    color: rgba(0,0,0,0.87);
    width:120px;
  }

  .dropdownDotClass .k-dropdown-wrap .k-input{
    height: 47px !important;
    margin-bottom: -10px;
  }
  .dropdownDotClass .k-dropdown-wrap .k-select .k-icon{
    margin-bottom: -10px;
  }
  .dropdownDotClass2 .k-input{
    text-overflow: unset;
    font-weight: 500;
    color: rgba(0,0,0,0.87);
    width:120px;
  }

  .dropdownDotClass2 .k-dropdown-wrap .k-input{
    height: 49px !important;
    margin-bottom: -10px;
  }
  .dropdownDotClass2 .k-dropdown-wrap .k-select .k-icon{
    margin-bottom: -10px;
  }
  .k-header{
    padding-right: 0px;
  }

  .paddingRight{
    padding-right: 0px;
  }

  .button,.k-button.k-state-active{
    box-shadow:none;
    text-transform: capitalize;
    background-color: rgb(59, 65, 86);
    font-size: 16px;
  }

  .toggleParagraph{
    margin-bottom: 0em;
  }

  /* .inputDotClass{
    font-size: 100%;
  } */

/*Tab strip Tab CSS*/
  .k-tabstrip>.k-content {
    padding: 0px;
}
.k-tabstrip-items .k-item > .k-link {
  z-index: 0;
}
.k-tabstrip-items .k-item.k-state-active::after {
  border-bottom: 3px solid #3f51b5;
}
.k-tabstrip-items .k-reset{
  border: 1px solid red;
}
 .k-tabstrip-items .k-link {
  padding: 14px 100px;
  color: white;
   background-color: #3b3f4f; 
} 


.k-tabstrip-items > .k-item:not(.k-state-active) {
  opacity: 1;
}
.k-tabstrip > .k-content.k-state-active {
  padding: 0px;
  
}
.k-tabstrip-top>.k-tabstrip-items {
  border-bottom-width: 0px; 
}
  .k-textbox-container>.k-label {
    color: rgba(0,0,0,0.65);
    font-weight: 300;
    font-size: 16px;
}

.dashboard-input-wrapper .k-textbox-container>.k-label {
  color: rgba(0,0,0,0.54);
  font-weight: 300;
  font-size: 16px;
}
.chart-donut  g g g path{
  stroke:  white;
  /* stroke-width: 3px; */
}
.chart-donut  g g g g{
  stroke-width: 1px;
}
/* .chart-donut  g g g g text{
  display: none;
} */
.chart-donut .k-chart .k-chart-surface{
  height: 150px;
}
.Tabstripcontent .k-tabstrip-items .k-link {
  padding: 14px 75px !important;
  color: #fff;
   background-color: #3b3f4f; 
}  

.Tabstripcontent .k-tabstrip-items .k-state-default .k-link {
  padding: 14px 75px !important;
  color: rgba(255,255,255,.7);
   background-color: #3b3f4f; 
}  
.Tabstripcontent .k-tabstrip-items{
width: auto !important;
background-color: #3b3f4f; 
border:1px solid #3b3f4f;
}  

.Tabstripcontent .k-content {
  width: auto !important;
}
.Tabstripcontent .k-content .k-animation-container{
  width: 100% !important;
}
.Tabstripcontent .k-content .k-animation-container .k-child-animation-container{

  width: auto !important;
}
/* .chart-donut g g g g text{
  opacity: 0;
  stroke: none;
} */

.offer-fixed-dashboard{
  padding-top: 0px;
  margin-top: 20px;
  padding-left: 5px;
  padding-bottom: 10px;
  width: 100%;
}
.div-currentOffer{
  background-color: #3b4156;
  padding: 15px;
}
.div-finalist{
  background-color: #81878D;
  padding: 15px;
  padding-right: 0px;
}
.font-currentOffer{
  padding-left: 0px;
  font-size: 14px;
  line-height: 12px; 
  margin-bottom: 13px;
  height: 18px;
  color: #e1f0fa;
}

.font-finalist{
  padding-left: 0px;
  font-size: 14px;
  line-height: 12px; 
  margin-bottom: 13px;
  height: 18px;
  color: #e7f5fa;
}

.font-currentOffer-dollar{
  padding-left: 0px;
  color: #e1f0fa;
  font-size: 25px; 
  line-height: 13px;
  font-weight: 400;
  height: 16px;
}

.font-finalist-dollar{
  padding-left: 0px;
  color: #e7f5fa;
  font-size: 25px; 
  line-height: 13px;
  font-weight: 400;
  height: 16px;
}

.div-candidatepool{
  padding: 15px; 
  padding-top: 8px;
}

.div-offerstrength{
  padding: 14px; 
  font-size: 14px;
}

.font-offerstrength{
  padding-top: 0px; 
  margin-bottom: 12px;
}

.font-candidatepool-offerstrength-dollar{
  padding-left: 0px;
  font-size: 25px;
  line-height: 13px;
  font-weight: 400;
  height: 16px;
}

.div-offer-candidate-grid{
  padding-bottom: 0px;
  padding-left: 20px;
}

.offer-candidate-h3{
  border-bottom: 1px solid #d9d9d9;
  padding-left: 20px;
  font-size: 18px;
  line-height: 66px;
}

.offer-candidate-h3-small{
  border-left: 1px solid #898989;
  padding-left: 5px;
  margin-left: 5px;
  color: #777;
}

.offer-candidate-h3-icon-vertical{
  color: rgba(0,0,0,0.54);
  font-size: 20px;
  font-weight: lighter;
  margin-right: 10px;
   margin-top: 10px;
}

.offer-candidate-h3-icon-plus{
  color: rgba(0,0,0,0.54);
  font-size: 20px;
  font-weight: lighter;
  margin-right: 35px;
  margin-top: 10px;
}

.offer-offerlayout-icon{
  color: rgba(0,0,0,0.54);
  font-size: 25px;
  font-weight: lighter;
  width: 50% !important;
  margin-right: 5px;
  margin-top: 0px;
  float: right;
  text-align: right;
}
/* for temporary purpose */
.k-list .k-item{
  padding-left: 10px;
}

.numeric-textbox-width .k-textbox-container{
  width: 60px;
}

.numeric-input-textbox-width .k-textbox-container{
  width: 100%;
}
.k-switch {
  margin-top: -120px;
  margin-left: 80px;
}
.offer-dashboard-paragraph{
  margin-top: -78px;
  margin-left: 130px;
}

.numeric2-textbox-width .k-textbox-container{
  width: 150px;
}

.offer-right-dashboard-tabstrip .k-tabstrip-items .k-link {
  padding: 16px 43px !important;
  background-color: #3b4156;
  color: rgba(255,255,255,.7);
  font-size: 10px;
  width: 140px;
}

.offer-right-dashboard-tabstrip .k-tabstrip-items .k-state-default .k-link {
  padding: 16px 43px !important;
  background-color: #3b4156;
  color: rgba(255,255,255,.7);
  font-size: 10px;
  width: 140px;
}

.k-tabstrip-items .k-item.k-state-active::after {
  border-bottom: 3px solid #2e8fff;
}

.offer-right-dashboard-tabstrip .k-tabstrip-items .k-item.k-state-active::after {
  border-bottom: 2px solid #2e8fff;
}

.offer-right-dashboard-tabstrip .k-animation-container {
  width: 100%;
}

/* .progress-linear-style g path{
  display: none;
} */

.div-main-offer-dashboard{
  width: 100%;
  /* margin-left: -15px;  */
  border-bottom: 1px solid #d9d9d9;
}
.tabstrip-div-main-offer-dashboard{
  padding-left: 20px;
  padding-right: 20px;
}
.tabstrip-div-row-offer-dashboard{
  color: #fff;
  font-size: 20px;
  line-height: 41px; 
  box-sizing: border-box;
   padding-top: 20px;
}

.tabstrip-div-row-column1-offer-dashboard{
  position: relative;
  padding-right: 50px;
}

.tabstrip-div-row-column2-offer-dashboard{
  position: relative;
  text-align: center;
  font-size: 10px;
  padding-bottom: 0px;
}

.tabstrip-div-row-column2-font-offer-dashboard{
  background-color: #58628F;
  border-color: #58628F;
  font-size: 20px;
  line-height: 50px;
  width: 24px;
  height: 48px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 100%;
  border: 2px solid #58628F;
  margin: 0px;
  box-sizing: content-box;
  margin-bottom: -10px;
  margin-left: -5px;
}
.offer-dashboard-input-box{
  width: 75px;
  margin-left: 0%;
}

.offer-dashboard-input-box .k-numeric-wrap {
  border-color: #3b4156;
  color: white;
  font-size: 24px;
}

.offer-dashboard-input-box .k-numeric-wrap .k-input {
  color: white;
  border-color: white;
  padding-left: 12px;
  font-size: 24px;
}

.offer-dashboard-rightside-input-box{
  width: 102px;
  margin-left: 0%;
}

.offer-dashboard-rightside-input-box .k-numeric-wrap {
  border-color: #3b4156;
  color: white;
  font-size: 24px;
}

.offer-dashboard-rightside-input-box .k-numeric-wrap .k-input {
  color: white;
  border-color: white;
  font-size: 24px;
  text-align: center;
}

.offer-popup-button .k-button{
  box-shadow: none;
  margin-top: -8px;
}

.offer-popup-button .k-button .k-icon{
  color: rgba(0,0,0,0.54);
  font-size: 24px;
}

.offer-popup-button:hover .tooltiptext {
  visibility: visible;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.offer-candidate-popup-button .k-button{
  box-shadow: none;
  margin-top: -30px;
  margin-left: -30px;
}

.offer-candidate-popup-button .k-button .k-icon{
  color: rgba(0,0,0,0.54);
  font-size: 20px;
  padding: 0px;
}

.div-modal .k-dialog-title{
  font-size: 18px;
  font-weight: 300;
}

.div-modal .k-overlay{
  z-index: 600;
  background-color: #000000;
  opacity: 0.5;
}

.div-modal .k-dialog-buttongroup{
  display: flex;
  width:100%;
  float: right;
  text-align: right;
  margin-bottom: 10px;
  margin-top: 20px;
}
.div-modal .k-window-titlebar{
  /* padding: 10px; */
  padding-bottom: 0px;
}
.div-modal .k-window-content{
  padding: 0px;
}
.div-modal .k-dialog-buttongroup .k-button{
  margin-right: 8px;
  display: flex;
  flex: 0;
  color: rgb(63,81,181);
}
.div-modal .k-dialog{
  min-width: 240px;
  max-width: 80%;
}

.popup-tabstrip .k-tabstrip-items .k-item.k-state-active::after {
  border-bottom: none;
}
.popup-tabstrip .k-tabstrip-items .k-link {
  padding: 14px 28.7px;
  background: #4a89dc;
    color: #fff; 
    }  

.offer-save-div-modal .k-content{
  padding: 0px;
}

.offer-save-div-modal .k-widget{
  width: 500px;
}
.offer-save-div-modal .k-overlay{
  z-index: 600;
  background-color: #000000;
  opacity: 0.5;
}

.offer-save-div-modal .k-dialog-buttongroup{
  display: flex;
  width:100%;
  float: right;
  text-align: right;
  margin-bottom: 10px;
  margin-top: 20px;
}
.offer-save-div-modal .k-dialog-buttongroup .k-button{
  margin-right: 8px;
  display: flex;
  flex: 0.1;
  color:inherit;
  padding-left: 26px;
  padding-right: 26px;
  width:100%;
}

.offer-save-input-field .k-textbox-container .k-label{
  color: rgba(0,0,0,0.38);
  font-weight: 700;
}

.offer-save-input-field .k-textbox{
  border-bottom-color: rgba(0,0,0,0.12) !important;
  border-color: none !important;
}

.offer-save-dialog-div{
  background-color: #3b3f4f;
  height: 56px;
  font-size: 18px;
  color: #f9f9f9; 
  display: flex;
  padding-top: 14px;
  width: 100%;
  border-top-left-radius: 3px;
   border-top-right-radius: 3px;
}

.offer-save-dialog-input-div{
  margin-top: 18px;
  margin-bottom: 18px;
  padding-left: 15px; 
  padding-right: 15px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.offer-dash-offer-save-dialog-button{
  background-color: #3b3f4f;
  border: none;
  color: #f9f9f9;
}
.popup-tabstrip ul li.k-state-active span::after{
  content:"";
  position:absolute;
  height:0;
  width:20px;
  left:100%;
  top:0;
  border:24px solid transparent;
  border-left: 20px solid #184c8f;
 }
 .popup-tabstrip ul li.k-state-active .k-link{
  background-color: #184c8f;
 }
 .popup-tabstrip .k-tabstrip-items{
  background-color: #4a89dc;
  border: 1px solid #4a89dc;
  border-left: none;
 }
 .modal-body {
  overflow-y:visible;
}
.tabstrip-label-font .k-textbox-container.k-state-empty>.k-label{
  font-weight: 700;
  color:rgba(0,0,0,0.38);
}
.tabstrip-label-font .k-textbox-container.k-state-focused>.k-label{
  color: #3f51b5;
}
.tabstrip-label-font .k-textbox-container .k-textbox{
  border-bottom-color: rgba(0,0,0,0.12) !important;
}
.tabstrip-label-font .k-textbox-container .k-autocomplete:hover{
  border-color: rgba(0,0,0,0.12) !important;
}
.tabstrip-label-font .k-textbox-container .k-state-focused {
  border-color: rgba(0,0,0,0.12) !important;
}

.tabstrip-label-font .k-textbox-container .k-dropdown  .k-dropdown-wrap:hover{
  border-color: rgba(0,0,0,0.12) !important;
}
.tabstrip-label-font .k-textbox-container .k-dropdown  .k-dropdown-wrap{
  border-color: rgba(0,0,0,0.12) !important;
}

.tabstrip-numerictextbox-label-font .k-textbox-container .k-label {
    font-weight: 700;
  border-color: rgba(0,0,0,0.12) !important;

}
.tabstrip-label-font1 .k-textbox-container.k-state-empty>.k-label{
  font-weight: 700;
  color:rgba(0,0,0,0.38);
}
.tabstrip-label-font1 .k-textbox-container.k-state-focused>.k-label{
  color: orangered
}
.tabstrip-label-font1 .k-textbox-container .k-textbox{
  border-bottom-color: rgba(0,0,0,0.12) !important;
}
.tabstrip-label-font1 .k-textbox-container .k-autocomplete:hover{
  border-color: rgba(0,0,0,0.12) !important;
}
.tabstrip-label-font1 .k-textbox-container .k-state-focused {
  border-color: rgba(0,0,0,0.12) !important;
}

.k-animation-container {
  z-index:100000; 
  opacity:1; 
}

.arrow-up-down{
    color: #717aa4;
    width: 80%;
    font-size: 19px;
}
.arrow-up-down:hover{
    color: #a0c9f0;
}
.arrow-up-down:active{
  -webkit-border-before-color: 2px solid #4285f4;
  border:2px solid #4285f4;
  opacity: 0.5;
  width:56px;
}
.right-side-toggle-button{
  color: white; 
  background-color: #3b4156;
  width: 100%; 
  margin-top: 10px; 
  margin-bottom: 0em !important;
  padding: 2px;
}
.right-side-toggle-button:focus{
  border-radius: 2%;
  border:1.5px solid #4285f4;
}
.k-list .k-item.k-state-selected {
  color: rgb(63,81,181);
}

.k-animation-container{
  /* width:auto !important; */
  height: auto !important;
  
}
.k-nodata{
  height: auto !important;
  min-height:  0px !important;
}

.dialog-font-size .k-dialog-title{
  font-size: 24px;
  font-weight: 400;
}

.div-modal .k-dialog{
  width: 60% !important;
  min-width: auto !important;
  border:5px solid white;
  margin: 5px !important;
  height: auto;
}

.power-dash-save-buttons-div .k-dialog-buttongroup .k-button {
  min-width: 86px !important;
  color: black;
}

.power-dash-save-buttons-div .k-dialog-buttongroup {
  margin-top: 0px !important;
}

.md-caption {
  font-size: 12px;
  letter-spacing: .020em;
}

.estimate-dashboard-header{
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 18px !important;
}

/* @media screen and (max-width: 1000px) {
  .TabstripCss ul.k-tabstrip-items {
    width: auto !important;
  }
} */

.power-user-dashboard-container{
  width: auto !important;
  
}
.power-user-dashboard-row{
  width: auto !important;
  border: 1px solid #d9d9d9;
  background-color:white;
}

.power-user-tabstrip-Tab{
  border:1px solid red;
}

.apollo-progress-markers {
  font-size: 11px;
  line-height: 11px;
  
  color: #9dacdd;
  position: relative;
  padding: 0 2px;
}

.apollo-progress-markers>.right {
  text-align: right;
}

.apollo-progress-markers>.center {
  text-align: center
}

.apollo-progress-markers>.center:after,.apollo-progress-markers>.left:after,.apollo-progress-markers>.right:after {
  content: " ";
  height: 5px;
  width: 1px;
  border-left: 1px solid #d9d9d9;
  position: absolute;
  bottom: 11px;
  left: 0
}

.apollo-progress-markers>.right:after {
  left: auto;
  right: 0;
  border-left: none;
  border-right: 1px solid #d9d9d9
}

.apollo-progress-markers>.center:after {
  left: 50%;
  margin-left: -1px
}

.apollo-progress-markers div {
  padding-left: 0;
  padding-right: 0;
}

.progress-bar-color .progressbar-progress{
  height: 5px !important;
  background-color: rgb(63,81,181) !important;
}

.progress-linear-style{
  margin:21px 0px 5px;
}

.offer-save-dropdown-field .k-textbox-container .k-label{
  color: rgba(0,0,0,0.38);
  font-weight: 700;
}

.offer-save-dropdown-field .k-textbox{
  border-bottom-color: rgba(0,0,0,0.12) !important;
  border-color: none !important;
}
.offer-dropdown-field{ 
  padding-left: 0px;
  margin-top: 10px;
 }

.offer-dropdown-field .k-textbox-container .k-dropdown{
  width:100% !important;
}

.offer-autocomplete-field{ 
  padding-left: 0px;
  margin-top: 10px;
 }
.offer-autocomplete-field .k-textbox-container .k-autocomplete{
  width:100% !important;
}

@media screen and (max-width: 600px) {
  .offer-save-div-modal .k-dialog{
    float: center;
    margin: 0px auto 5px 20px;
    width: 90%;
    height: 80%;
  }
}

.offer-dropdown-field .k-textbox-container .k-label {
  color: rgba(0,0,0,0.38);
  font-weight: 700;
}
.offer-autocomplete-field .k-textbox-container .k-label {
  color: rgba(0,0,0,0.38);
  font-weight: 700;
}
.modal-backdrop.fade {
  opacity: 0;
}

.arrow-left-right{
  color: #717aa4;
  /* width: 80%; */
  border:2px solid #3b4156;
  font-size: 19px;
  padding-top: 3px;
  padding-bottom: 3px;

}
.arrow-left-right:hover{
  color: #a0c9f0;
}
.arrow-left-right:active{
-webkit-border-before-color: 2px solid #4285f4;
border:2px solid #4285f4;
opacity: 0.5;
font-size: 19px;
/* width:80%; */
padding-top: 3px;
padding-bottom: 3px;
}

.data-input-cash-percent-box{
  width:52px;
  /* margin-left: -25%; */
  padding-left: 6px;
  text-align: center;
}
.data-input-cash-percent-box .k-numeric-wrap {
  border-color: #3b4156;
  color: white;
  font-size: 20px;
  
}
.data-input-cash-percent-box .k-input {
  text-overflow: unset;
  padding-left: 0px;
  margin-left: 0px !important;
}
.data-input-cash-percent-box .k-numeric-wrap .k-input {
  color: white;
}
.data-input-cash-percent-box .k-numeric-wrap.k-state-invalid{
  color: None;
  border: 0px;
}




@media screen and (max-width: 1075px) {
  .Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 40px !important;
    color: #fff;
     background-color: #3b3f4f; 
  }
  .Tabstripcontent .k-tabstrip-items .k-state-default .k-link {
    padding: 14px 40px !important;
    color: rgba(255,255,255,.7);
     background-color: #3b3f4f; 
  }    
}

@media screen and (max-width: 776px) {
  .Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 20px !important;
    color: #fff;
     background-color: #3b3f4f; 
  }  
  .Tabstripcontent .k-tabstrip-items .k-state-default .k-link {
    padding: 14px 20px !important;
    color: rgba(255,255,255,.7);
     background-color: #3b3f4f; 
  }  
}
@media screen and (max-width: 615px) {
  .Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 0px !important;
    color: #fff;
     background-color: #3b3f4f; 
     display: inline-block;
    width: 120px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }  
}

@media screen and (max-width: 567px) {
  .Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 0px !important;
    color: #fff;
     background-color: #3b3f4f; 
     display: inline-block;
    width: 100px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }  
}
@media screen and (max-width: 495px) {
  .Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 0px !important;
    color: #fff;
     background-color: #3b3f4f; 
     display: inline-block;
    width: 58px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }  
}

.offer_arrow-up-down{
  color: #717aa4;
  width: 30px;
  font-size: 19px;
  text-align: center;
  border:2px solid white;
  
}
.offer_arrow-up-down:hover{
  color: #a0c9f0;
}
.offer_arrow-up-down:active{
-webkit-border-before-color: 2px solid #4285f4;
border:2px solid #4285f4;
opacity: 0.5;
width:30px;
}

.offer-dashboard-right{
  top:-0.8px;
    background-color: #3b4156;
    box-shadow: inset 0 0 8px -3px #000;
    padding: 0px;
}

.personalDashboardHeaderDiv{
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
}

.personal_dashboard_header_user_icon{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* z-index: 20; */
  background-clip:  padding-box;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  background-color: #58628f;
  /* float: right; */
  margin-right: 0px;
}

.personal_dashboard_header_layout1_first_div{
  padding-top: 0px;
  padding-left: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.personal_dashboard_header_layout1_shadow_div{
  background-color: #14225a;
  box-shadow: inset 0 4px 8px -5px #000;
   color: #fff;
}

.personal_dashboard_header_layout1_user_icon_div{
  padding-top: 20px;
   padding-left: 2px;
}
.personal_dashboard_header_icon{
  margin-left: 9px;
  margin-top: 8px;
  font-size: 22px;
}

.personal_dashboard_header_layout1_h2{
  font-weight: 600;
   font-size: 20px;
}

.personal_dashboard_header_layout1_p{
  font-weight: 600;
  font-size: 13px;
}

.personal_dashboard_header_div{
  margin: 40px; 
  margin-right: 27px;
  margin-bottom: 0px;
}

.personal_dashboard_header_bootstrap_div{
  background-color: #58628F;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #cccfdd; 
  font-size: 17px;
}



.personal_dashboard_header_layout1_third_circle{
  font-size: 20px;
  line-height: 20px;
  width: 48px;
  height: 20px;
  border: 2px solid #58628F;
  padding: 14px 0;
  margin: 0 auto;
  border-radius: 100%;
  color: #FFF;
  background: #58628F;
  box-sizing: content-box;
  text-align: center;
}

.personal_dashboard_header_layout1_third_circle_char{
  text-align: center;
  font-weight: 600;
}
.personal_dashboard_header_layout{
  background-color: #434963;
  box-shadow: inset 0 4px 8px -3px #000;
}

.personal_dashboard_header_layout_total_circle{
  font-size: 20px;
  line-height: 25px;
  width: 48px;
  height: 20px;
  border: 2px solid #58628F;
  padding: 14px 0;
  margin: 0 auto;
  border-radius: 100%;
  color: #FFF;
  background: #58628F;
  box-sizing: content-box;
  text-align: center;
}

.tabstrip_div_main_personal_dashboard{
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
}

.personal_dashboard_header_layout .k-header{
  margin-left:-10px;
}

.personal_dashboard_header_layout .k-header .k-tabstrip-items .k-item .k-link{
  /* background-color; */
  background-color: unset;
}
.personal_dashboard_header_layout .k-animation-container {
  width: 100%;
}
.personal_dashboard_header_layout .k-tabstrip-items .k-link {
  padding: 16px 36px !important;
  font-size: 12px;
}

.personal_dashboard_header_layout2_total_circle_char{
  text-align: center;
  font-size: 12px;
  padding-top: 0px;
}

.personal_dashboard_header_layout2_Total_circle{
  font-size: 20px;
  line-height: 21px;
  width: 48px;
  height: 20px;
  border: 2px solid #58628F;
  padding: 14px 0;
  margin: 0 auto;
  border-radius: 100%;
  color: #FFF;
  background: #58628F;
  box-sizing: content-box;
  text-align: center;
}

.personal_dashboard_header_layout2_Total_circle_bootstrap{
  position: relative;
  text-align: center;
  font-size: 10px;
  padding-bottom: 0px;
}

.personal_dashboard_progress_bar_color .progressbar-progress{
  height: 5px !important;
  background-color: #fe8116 !important;
}


.personal_dashboard_Tabstripcontent {
  padding-left: 39px !important;
  padding-right: 25px !important;
  width: 100% !important;
}  
.personal_dashboard_Tabstripcontent .k-tabstrip-items{
width: 100% !important;
box-shadow: 0 8px 6px -6px #000;
}  

.personal_dashboard_Tabstripcontent .k-content {
  width: 100% !important;
}
.personal_dashboard_Tabstripcontent .k-content .k-animation-container{
  width: 100% !important;
}
.personal_dashboard_Tabstripcontent .k-content .k-animation-container .k-child-animation-container{

  width: 100% !important;
}





.personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
  padding: 14px 55px !important;
  color: #717171;
   background-color: unset; 
   width: 268px;
} 
.personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
  color: white;
   background-color: #58628F; 
   width: 250px;
} 
@media screen and (max-width: 1175px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 40px !important;
    color: #717171;
     /* background-color: #3b3f4f;  */
     width: 264px;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 242px;
  } 
}
@media screen and (max-width: 1150px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 40px !important;
    color: #717171;
     width: 240px;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 229px;
  } 
}
@media screen and (max-width: 1150px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 40px !important;
    color: #717171;
     width: 240px;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 229px;
  } 
}

@media screen and (max-width: 1058px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 40px !important;
    color: #717171;
     width: 225px;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 210px;
     font-size: 12px;
  } 
}
@media screen and (max-width: 1024px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 40px !important;
    color: #717171;
     width: 231px;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 210px;
     font-size: 12px;
  } 
}
@media screen and (max-width: 990px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 40px !important;
    color: #717171;
     width: 193px;
     font-size: 12px;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 184px;
     font-size: 10px;
  } 
}


.personal_dashboard_Tabstripcontent ul li.k-state-active span::after{
  content:"";
  position:absolute;
  height:0;
  width:20px;
  left:100%;
  top:0;
  border:24px solid transparent;
  border-left: 20px solid #58628F;
 }

 @media screen and (max-width: 873px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 40px !important;
    color: #717171;
     width: 177px;
     font-size: 9px;
     text-overflow: ellipsis;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 164px;
     font-size: 9px;
     
  }
  .personal_dashboard_Tabstripcontent ul li.k-state-active span::after{
    content:"";
    position:absolute;
    height:0;
    width:20px;
    left:100%;
    top:0;
    border:19px solid transparent;
    border-left: 20px solid #58628F;
   }
}

@media screen and (max-width: 812px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 40px !important;
    color: #717171;
     width: 117px;
     font-size: 9px;
     text-overflow: ellipsis;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 164px;
     font-size: 9px;
     
  }
  .personal_dashboard_Tabstripcontent ul li.k-state-active span::after{
    content:"";
    position:absolute;
    height:0;
    width:20px;
    left:100%;
    top:0;
    border:27px solid transparent;
    border-left: 20px solid #58628F;
   }
}
@media screen and (max-width: 768px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 30px !important;
    color: #717171;
     width: 167px;
     font-size: 10px;
     text-overflow: ellipsis;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 154px;
     font-size: 10px;
     
  }
  .personal_dashboard_Tabstripcontent ul li.k-state-active span::after{
    content:"";
    position:absolute;
    height:0;
    width:20px;
    left:100%;
    top:0;
    border:20px solid transparent;
    border-left: 20px solid #58628F;
   }
}
@media screen and (max-width: 628px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 14px 40px !important;
    color: #717171;
     width: 117px;
     font-size: 9px;
     text-overflow: ellipsis;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 164px;
     font-size: 9px;
     
  }
  .personal_dashboard_Tabstripcontent ul li.k-state-active span::after{
    content:"";
    position:absolute;
    height:0;
    width:20px;
    left:100%;
    top:0;
    border:27px solid transparent;
    border-left: 20px solid #58628F;
   }
}
@media screen and (max-width: 414px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 4px 64px 0px 13px !important;
    color: #717171;
     width: 50px !important;
     font-size: 9px;
     text-overflow: ellipsis;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 14px;
     font-size: 9px;
     
  }
  .personal_dashboard_Tabstripcontent ul li.k-state-active span::after{
    content:"";
    position:absolute;
    height:0;
    width:20px;
    left:100%;
    top:0;
    border:15px solid transparent;
    border-left: 10px solid #58628F;
   }
}

@media screen and (max-width: 411px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 4px 63px 0px 13px !important;
    color: #717171;
     width: 50px !important;
     font-size: 9px;
     text-overflow: ellipsis;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 14px;
     font-size: 9px;
     
  }
  .personal_dashboard_Tabstripcontent ul li.k-state-active span::after{
    content:"";
    position:absolute;
    height:0;
    width:20px;
    left:100%;
    top:0;
    border:15px solid transparent;
    border-left: 10px solid #58628F;
   }
}
@media screen and (max-width: 375px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 4px 55px 0px 13px !important;
    color: #717171;
     width: 50px !important;
     font-size: 9px;
     text-overflow: ellipsis;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 14px;
     font-size: 9px;
     
  }
  .personal_dashboard_Tabstripcontent ul li.k-state-active span::after{
    content:"";
    position:absolute;
    height:0;
    width:20px;
    left:100%;
    top:0;
    border:15px solid transparent;
    border-left: 10px solid #58628F;
   }
}
@media screen and (max-width: 360px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 4px 51px 0px 13px !important;
    color: #717171;
     width: 50px !important;
     font-size: 9px;
     text-overflow: ellipsis;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 14px;
     font-size: 9px;
     
  }
  .personal_dashboard_Tabstripcontent ul li.k-state-active span::after{
    content:"";
    position:absolute;
    height:0;
    width:20px;
    left:100%;
    top:0;
    border:15px solid transparent;
    border-left: 10px solid #58628F;
   }
}
@media screen and (max-width: 320px) {
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-link {
    padding: 4px 41px 0px 13px !important;
    color: #717171;
     width: 50px !important;
     font-size: 9px;
     text-overflow: ellipsis;
  }  
  .personal_dashboard_Tabstripcontent .k-tabstrip-items .k-state-active .k-link {
    color: white;
     background-color: #58628F; 
     width: 14px;
     font-size: 9px;
     
  }
  .personal_dashboard_Tabstripcontent ul li.k-state-active span::after{
    content:"";
    position:absolute;
    height:0;
    width:20px;
    left:100%;
    top:0;
    border:15px solid transparent;
    border-left: 10px solid #58628F;
   }
}
 .personal_dashboard_target_pay_tabstrip_circle{
  font-size: 20px;
  line-height: 40px;
  width: 88px;
  height: 40px;
  border: 2px solid #58628F;
  padding: 14px 0;
  margin: 0 auto;
  border-radius: 100%;
  color: #FFF;
  background: #58628F;
  box-sizing: content-box;
  text-align: center;
  margin-top: 20px;
}


.personal_dashboard_up_down{
  color: #bfbfbf;
  width: 80%;
  font-size: 25px;
}
.personal_dashboard_up_down:hover{
  color: #bfbfbf;
}
.personal_dashboard_up_down:active{
-webkit-border-before-color: 2px solid #4285f4;
border:2px solid #4285f4;
opacity: 0.5;
width:56px;
}

.target_pay_rating_circle_div{
  text-align: center;
   margin-top: 20px;
}
.rate_offer_circle_div{
  text-align: center;
   margin-left: 0px;
   padding: 0px;
}
.target_pay_rating_circle_div2{
  width: 100px;
  border-radius: 100%;
  margin: 0 auto;
  background: #58628F;
  border: 1px solid #58628F;
  line-height: 25px;
}

.target_pay_rating_circle_div3{
  width: 140px;
  border-radius: 100%;
  margin: 0 auto;
  border: 3px solid green;
  height: 113px;
}

.target_pay_rating_circle_inside_div1{
  color: rgba(0,0,0,0.65);
  font-weight: 600; 
  margin-top: 30px; 
  line-height: 15px;
}

.target_pay_rating_circle_inside_div2{
  color: rgba(0,0,0,0.72);
   font-weight: 600;
   font-size: 23px;
}

.target_pay_rating_circle_div4{
  width: 140px;
  border-radius: 100%;
  margin: 0 auto;
  border: 3px solid #4689d3;
  height: 113px;
}

.target_pay_rating_circle_arrow_div{
  margin-top: 35px;
  margin-left: 10px;
  padding: 0px;
}

.target_pay_rating_circle_arrow_div2{
  border: 1px solid red;
  height: 35px;
  padding: 0px;
  width: 0px;
  margin-left: 6px;
  margin-bottom: -13px;
}

.target_pay_rating_circle_right_arrow_div{
  border: 1px solid green;
  height: 35px;
  padding: 0px;
  width: 0px;
  margin-left: 6px;
  margin-top: -12px;
}

.analytics-dashboard{
  margin: 30px auto;
  margin-right:auto;
  margin-left:auto;
  padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 1200px){
  .analytics-dashboard {
    width: 1170px;
}
}

.k-animation-container-shown, .k-animation-container>.k-popup {
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.12);
}


.footer-color {
  color: #a5a5a5;
}

.p20 {
  padding: 22px;
}

.rate_offer_dashboard_icon_save{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 20;
  background-clip:  padding-box;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  background-color: #58628f;
  float: right;
}

.rate_offer_dashboard_floppy_icon{
  margin: auto;
  color: rgba(255,255,255,0.87);
  font-size: 24px;
  line-height: 1;
  margin-left: 0px;
  margin-top: 7px;
}

.rate_offer_dashboard_right_side_layout{
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  padding: 0px;
}

.rate_offer_dashboard_right_side_saved_offer{
  text-align: center;
  padding: 3px 0px;
  background-color: #58628F;
  color: rgb(255, 255, 255);
}

.rate_offer_dashboard_right_side_first_layout{
  padding: 10px;
  padding-top: 15px;
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
.rate_offer_circle_div3{
  width: 140px;
  border-radius: 100%;
  margin: 0 auto;
  border: 3px solid #0052CC;
  height: 113px;
}

.rate_offer_circle_div4{
  width: 140px;
  border-radius: 100%;
  margin: 0 auto;
  border: 3px solid #fe8116;
  height: 113px;
}

.rate_offer_dashboard_right_side_rating{
  color:#4B0082;
  font-weight: bold;
  padding-right: 12px;
}

.rate_offer_dashboard_right_side_candidate_data{
  color:rgba(0,0,0,0.32);
  text-align: left;
  font-weight: bold;
  font-size: 13px;
  line-height: 1.2;
}

.rate_offer_dashboard_right_side_date_div{
  text-align: right;
  color:rgba(0,0,0,0.40);
}
.rate_offer_dashboard_right_side_date_div2{
  text-align: left;
  color:rgba(0,0,0,0.40);
  margin-top: 6px;
}

.rate_offer_dashboard_right_side_second_layout{
  padding: 10px;
  padding-top: 8px;
  padding-left: 35px;
  border-bottom: 1px solid rgba(0,0,0,0.12);
  padding-bottom: 5px;
}

.rate_offer_circle_div2{
  width: 100px;
  border-radius: 100%;
  margin: 0 auto;
  background: #58628F;
  border: 1px solid #58628F;
  line-height: 85px;
}

.inputDotClassRateOffer .k-input{
  text-overflow: unset;
  font-weight: 800;
  color: rgba(0,0,0,0.87);
}

.rate_offer_numeric_text_box{
  font-weight: 800;
}


.offer-saved-offer-div-modal .k-content{
  padding: 0px;
}

.offer-saved-offer-div-modal .k-widget{
  width: 1000px;
}
.offer-saved-offer-div-modal .k-overlay{
  z-index: 600;
  background-color: #000000;
  opacity: 0.5;
}

.offer-saved-offer-div-modal .k-dialog-buttongroup{
  display: flex;
  width:100%;
  float: right;
  text-align: right;
  margin-bottom: 10px;
  margin-top: 20px;
}
.offer-saved-offer-div-modal .k-dialog-buttongroup .k-button{
  margin-right: 8px;
  display: flex;
  flex: 0.1;
  color:inherit;
  padding-left: 26px;
  padding-right: 26px;
  width:100%;
}

/* @media screen and (max-width: 776px) {
  .offer-saved-offer-div-modal .k-widget{
    width: 560px;
  }
} */

@media screen and (max-width: 600px) {
  .offer-saved-offer-div-modal .k-dialog{
    float: center;
    margin: 0px auto 5px 20px;
    width: 90%;
    height: 80%;
  }
}

.saved_offers_name{
  text-overflow:unset !important;
}
.numeric-textbox-width-bonus .k-textbox-container{
  width: 50px;
}