.register-middle-box{
  width: 25%;
  float: left;
  height: 100%;
  background-color:rgba(255,255,255,.85);
  margin-top: 10%;
  margin-bottom: 2%;
  margin-left: 15%;
  width: 67%;
  border-top: 5px solid #22aae4;
  padding: 0px;
  text-align: justify;
  height: 47%;
  opacity: 0.9;
}

.signup-card-box{
  background-color:rgba(255,255,255,.85);
  margin-top: 10%;
  margin-bottom: 2%;
  margin-left: 15%;
  border-top: 5px solid #22aae4;
  padding: 0px;
  text-align: justify;
  height: 100%;
  opacity: 0.9;
}

.master-register-paragraph{
  color: black; text-align: center; font-size: 30px; margin-top: 0px; font-weight: 500; margin-bottom: 20px;
}

.master-register-div{
  margin-left: -250px; width: 100%;
}
.master-register-signup-button{
  color: white;
  font-weight: 350;
  width: 90%;
  background-color: #22aae4;
  display: inline-block;
  /* format-number: "400", */
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 12px 15px;
  height: 50px;
  font-size: 24px;
  line-height: 1.428571429;
  border-radius: 4px;
  margin-left: 80px;
  padding-top: 5px;
}

.register-master-header{
  font-family: Open Sans; text-align: center; font-weight: 500; margin-left: 40px;
}

.master-register-linkedIn{
  width: 90%;
  margin-left: 80px;
  border-radius: 4px;
  border: 1px solid transparent;
  padding-top: 5px;
  background-color: #0371a9;
  text-transform: none;
  font-size: 24px;
  font-weight: 50;
  color: white;
  text-align: center;
  height: 45px;
  vertical-align: middle;
  margin-top: 0px;
}

.master-signup-linkedIn{
  width: 100%;
  border-radius: 4px;
  border: 1px solid transparent;
  padding-top: 5px;
  background-color: #0371a9;
  text-transform: none;
  font-size: 24px;
  font-weight: 50;
  color: white;
  text-align: center;
  height: 45px;
  vertical-align: middle;
  margin-top: 0px;
}


a.master-signup-linkedIn:hover{
  color:white;
  background-color:#0371a9;
}
a.btn-default:hover{
  color:black;
  background-color:white;
}

.master-signup-linkedIn-icon{
  margin-left: -280px; margin-right: 25px; font-size: 25px; margin-top: 10px;
}
.master-signup-linkedIn-line{
  border-left: 1px solid #fff;
  height: 48px;
  margin-bottom: -10px;
}
.master-signup-linkedIn-button-font{
  margin-left: 240px;
  font-weight: 500;
  vertical-align: middle;
}


a.master-register-linkedIn:hover{
  color:white;
  background-color:#0371a9;
}
a.btn-default:hover{
  color:black;
  background-color:white;
}

.master-register-linkedIn-icon{
  margin-left: -240px; margin-right: 25px; font-size: 25px; margin-top: 10px;
}
.master-register-linkedIn-line{
  border-left: 1px solid #fff;
  height: 45px;
  margin-bottom: -10px;
}
.master-register-linkedIn-button-font{
  margin-left: 180px;
  font-weight: 500;
  vertical-align: middle;
}

