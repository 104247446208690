
.landing {
  position: fixed;
  background: url("./img/background-3.jpg") no-repeat;
  background-size: cover;
  background-position: cover;
  height: 100%;
  width:100%;
}

.landing-header-wrapper{
  margin-top: 4em;
}

.landing-logo 
{
  height: 60px; 
  width: 200px; 
  margin-left: 80px;
}

.landing-login{
  color: white;
  font-size: 17px;
  padding-left: 15px;
  float: right;
  font-family: Arial;
  margin-right: 120px;
  margin-top: 22px;
  text-decoration: none;
  /* color: inherit; */
}

a.landing-login:hover { color: white; 
  text-decoration: none 
}

.landing-signup{
  color: white;
  font-size: 17px; 
  float: right;
  padding-right: 15px;
  margin-top: 22px;
  text-decoration: none;
  font-family: Arial;
  border-right: 2px solid white;
}
a.landing-signup:hover { 
  color: white; 
  text-decoration: none 
}
.header{
  color: white;
  font-family: Arial;
  text-align: center;
  font-size: 18px;
  margin-top: 19%;
}
.sidebar{
  width: 15%;
}
