
.card-layout {
  margin: 20px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 
  0px 1px 1px 0px rgba(0, 0, 0, 0.14),
  0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color:#eceef2;
  transition: 0.3s;
  width: 60%;
  margin-left: 20%;
  border-radius: 2px;
  background-color: white;
}

fieldset > h1 {
  padding-left : 0px !important;
  font-size: 24px !important;
  margin-top: -20px !important;
  font-weight: normal;
}

.k-label {
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  margin-top: -10px;
  font-weight: 500;
}

.password-form-field-height {
margin-top:15px;
padding-left: 60px;
}

.k-textbox {
  border-color: #333333 !important;
}
.password-submit-button{
  margin-left: 240px;
  margin-right: 20px;
  padding-left: 25px;
  padding-right: 25px;
}

.password-validation-message{
  color: red;
   padding-left: 60px;
}

.password-success-message{
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #3b4156;
  padding: 10px;
  width: 25%;
  text-align: center;
  color: white;
}

.password-footer-div{
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: "";
  width: 100%;
}

.password-footer-tag{
  text-align: center; font-size: 15px; color: #a5a5a5;
}
.change-password-cancel-button{
  margin-top: 16px;
}
.change-password-cancel-button {
  color: black;
  margin-top: 16px;
}
.change-password-cancel-button:hover {
   color: black;
   border:0px;
   background-color: #fff;
}