.login-right-menu{
  width: 25%;
  float: right;
  height: 100%;
  background-color:rgba(255,255,255,.85);
  /* opacity: 0.9; */
}
.input-wrapper-envelope {
  display:inline-block;
  position: relative;
  width: 100%;
}
.input-wrapper-envelope:after {
  font-family: 'FontAwesome';
  content: '\f0e0';
  position: absolute;
  color: gray;
  left: 15px;
  top:12px;
}

.input-wrapper-lock {
  display:inline-block;
  position: relative;
  width: 100%;
}
.input-wrapper-lock:after {
  font-family: 'FontAwesome';
  content: '\f023';
  position: absolute;
  color: gray;
  left: 15px;
  top:3px;
}


.input-wrapper-reset-password-lock {
  display:inline-block;
  position: relative;
  width: 100%;
}
.input-wrapper-reset-password-lock:after {
  font-family: 'FontAwesome';
  content: '\f023';
  position: absolute;
  color: gray;
  left: 15px;
  top:12px;
}

.input-wrapper-envelope-forgot-password {
  display:inline-block;
  position: relative;
  width: 100%;
}
.input-wrapper-envelope-forgot-password:after {
  font-family: 'FontAwesome';
  content: '\f0e0';
  position: absolute;
  color: gray;
  left: 15px;
  top:-6px;
}
.text-muted {
  color: #777;
  font-family: Lato,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.428571429;
  font-weight: 400;
  text-align:left;
  margin-top: 30px;
}

#LoginButton{
  width: 100%; 
  border-radius: 5px;
  background-color: #22aae4;
  border-color: #22aae4;
  font-size: 14px;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
}

.react-new-login-btn {
  display: inline-block;margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  user-select: none;
}

.angular-new-login-btn-primary {
  color: #fff;background-color: #22aae4;border-color: #1a9bd3;
}

#linked-in-button{
  background-color: #0177b5;
  border-radius: 5px;
  border:1px solid #0177b5;
  width: 100%;
  margin-top: 15px;
  height: 34px;
}

#linked-in-icon{
  color: white;
  background-color: #0177b5;
  font-size: 22px;
  padding-left: 0px;
  margin-right: 10px;
}

.linkedLine{
  color: white;
  font-size: 40px;
  margin-top: -32px;
  margin-bottom: -27px;
  margin-left:-15px;
}

.linked-in-font-name{
  margin-left: 5px;
  color: white;
}

.k-textbox-container .k-state-focused::after{
	opacity:0;
}

.signUprole.k-state-invalid > .k-dropdown-wrap {
  border: 0px solid white;
}

.k-form fieldset, .k-form-inline fieldset {
  border-width: 1px 0 0;
  border-style: none;
  margin: 64px 0;
  padding: 0;
}

.forgot-password{
  float: right;
  width: 100%;
  text-align: right;
  color: #22aae4;
  text-decoration: none;
  margin-top: 10px;
}

.paragraph-account{
  text-align: right;
  font-weight: 200;
}

/**
  * style variables
*/
/**
  * Control & indicator mixin
*/
@media screen and (max-width: 600px) {
  #title_message {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .carousel {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}
.carousel {
  height: 350px;
  width: 200%;
  overflow: hidden;
  text-align: center;
  position: relative;
  list-style: none;
  background-color: rgba(255,255,255,.85);
  border-top: 5px solid #22aae4;
  padding: 10px 10px 40px;
  text-align: justify;
  margin-top: 50%;
}
.carousel__controls,
.carousel__activator {
  display: none;
  border-color: #22aae4;
  }
.carousel__activator:nth-of-type(1):checked ~ .carousel__track {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.carousel__activator:nth-of-type(1):checked ~ .carousel__slide:nth-of-type(1) {
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.carousel__activator:nth-of-type(1):checked ~ .carousel__controls:nth-of-type(1) {
  display: block;
  opacity: 1;
}
.carousel__activator:nth-of-type(1):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(1) {
  opacity: 1;
}
.carousel__activator:nth-of-type(2):checked ~ .carousel__track {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.carousel__activator:nth-of-type(2):checked ~ .carousel__slide:nth-of-type(2) {
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.carousel__activator:nth-of-type(2):checked ~ .carousel__controls:nth-of-type(2) {
  display: block;
  opacity: 1;
}
.carousel__activator:nth-of-type(2):checked ~ .carousel__indicators .carousel__indicator:nth-of-type(2) {
  opacity: 1;
}

.carousel__control {
  height: 30px;
  width: 30px;
  margin-top: -15px;
  top: 50%;
  position: absolute;
  display: block;
  cursor: pointer;
  border-width: 8px 8px 0 0;
  border-style: solid;
  border-color: #22aae4;
  opacity: 0.35;
  outline: 0;
  z-index: 3;
}
.carousel__control:hover {
  opacity: 1;
}
.carousel__control--backward {
  left: 10px;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
}
.carousel__control--forward {
  right: 10px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.carousel__indicators {
  position: absolute;
  bottom: 5px;
  width: 60%;
  text-align: center;
}
.carousel__indicator {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-block;
  z-index: 2;
  cursor: pointer;
  opacity: 0.35;
  margin: 0 2.5px 0 2.5px;
}
.carousel__indicator:hover {
  opacity: 0.75;
}
.carousel__track {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0;
  transition: -webkit-transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
}
.carousel__track .carousel__slide {
  display: block;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
}
.carousel__track .carousel__slide:nth-of-type(1) {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.carousel__track .carousel__slide:nth-of-type(2) {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.carousel--scale .carousel__slide {
  -webkit-transform: scale(0);
          transform: scale(0);
}
.carousel__slide {
  height: 100%;
  position: absolute;
  overflow-y: auto;
  opacity: 0;
}
/**
  * Theming
*/
* {
  box-sizing: border-box;
}

.carousel-container {
  display: inline-block;
  margin-left: 8%;
  margin-top: 10%
}
.my-carousel {
  border-radius: 5px;
  margin: 30px;
}
.carousel__slide {
  overflow: hidden;
}
.carousel--thumb .carousel__indicator {
  height: 30px;
  width: 30px;
}
.candidate-slide {
  font-size: 36%;
  line-height: 100%;
  position: absolute;
  top: 5%;
  width: 100%;
  margin-top: -55px;
}

h3 {
  font-size: 50px;
}
.carousel__indicator {
  background-color: #22aae4;
  width: 10px;
  height: 10px;
}

.carousel-inner{
  background-color: white;
  height: 320px;
}
.carousel-control-next {
  width: 0px;
  cursor: none;
  opacity: 0.5;
  background-image: none;
  background-image: none;
}
.carousel-control-prev {
  width: 0px;
  cursor: none;
  opacity: 0.5;
  background-image: none;
  background-image: none;
}
.carousel-control-next:hover, .carousel-control-prev:hover {
  opacity: 1;
  color: white;
  background-image: none !important;
  background-image: none;
}
.rightControl > i{
    right: 30%;
    margin-right: -10px;
    position: absolute;
    top: 50%;
    z-index: 2;
    display: inline-block;
    margin-top: -10px;
    font-size: 25px;
    box-shadow: white;
}


.icon-chevron-right:before {
  content: "\62";
  margin-left: 10px;
  color: #22aae4;
}
.icon-chevron-left:before {
  content: "\62";
  color: #22aae4;
}

ol.carousel-indicators .active {
  background-color: #22aae4;
  border-radius: 100%;
    height: 10px;
    width: 10px;
}

ol.carousel-indicators li {
  background-color: #0177b5;
  border-radius: 100%;
  height: 8px;
  width: 8px;
  border: 0px solid;
}

.carousel-bold-tag {
  margin-left: 220px;
  font-family: Lato;
  font-size: 35px;
  text-align: right;
}

.carousel-div-tag-one {
  margin-left: 50px;
  color: #60605e;
  margin-right: 50px;
  margin-top: 60px;
}

.carousel-paragraph-tag-one {
  font-size: 16px;
  font-family: Lato;
}

.carousel-bold-tag-two{
  font-family: Lato;
  font-weight: 700;
  font-size: 16px;
  color: #60605e;
}

.carousel-div-tag-two{
  margin-left: 50px;
  color: #60605e;
  margin-right: 50px;
}

.carousel-div-tag-three{
  margin-left: 50px;
  color: #60605e;
  margin-right: 50px;
}

.carousel-bold-tag-three{
  margin-left: 220px;
  font-family: Lato;
  font-size: 35px;
  text-align: right;
}
.carousel-div-tag-four{
  margin-left: 50px;
  color: #60605e;
  margin-right: 50px;
   margin-top: 60px;
}
