.maindiv{
    width: 63%; 
    margin-left: 20%; 
    background-color: white;
     margin-bottom: 50px;
     box-shadow: "0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12)";
     margin-top: 20px;
  }
  h1s{
      font-weight: normal;
      font-size: 0.25rem
  }
  .h3s{
      
      margin-top: 40px;
      font-size: 18px; 
      font-weight: 500
  }
  .h3details{
    margin-right:300px;
    font-size: 18px; 
    font-weight: 500;
    margin-top: 15%;
  }
  .h3location{
    margin-right:300px;
    font-size: 18px;
    font-weight: 500;
    margin-top: 5%
  }
  .h3experience{
    font-size: 18px;
     font-weight: 500;
     margin-top: 8%;
    margin-bottom: 5%
  }

     