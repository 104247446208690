.loginLeftMenu{
  width: 25%;
  float: left;
  height: 100%;
  background-color:rgba(255,255,255,.85);
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 300px;
  width: 50%;
  background-color: rgba(255,255,255,.85);
  border-top: 5px solid #22aae4;
  padding: 0px;
  text-align: justify;
  height: 95%;
  opacity: 0.9;
}

#LoginButton{
  width: 100%; 
  border-radius: 5px;
  background-color: #22aae4;
  border-color: #22aae4;
  font-size: 14px;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
}

.register-with-email-paragraph{
  text-align: center; font-size: 22px; margin: 2 -20 5px;
}

.register-with-email-logo{
  margin-top: 20px; width: 45%; margin-bottom: -20px; margin-left: 188px;
}

.signup-with-email-logo{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.signup-with-email-input-field{
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  width: 85%;
  height: 35px;
  background-color: #FFFFFF;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 25px;
}


.register-with-email-input-field{
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  width: 70%;
  height: 35px;
  background-color: #FFFFFF;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 25px;
}

.register-with-email-compboBox-field{
  padding-left: 10px;
  border-radius: 5px;
  width: 70%;
  height: 35px;
  background-color: #FFFFFF;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 25px;
  border-color: 10px solid white;
}


.register-with-email-compboBox-field.k-state-invalid > .k-dropdown-wrap {
  border: 0px solid white;
}

.register-with-email-compboBox-field .k-dropdown-wrap{
  border: 0px solid white;
}


.signup-with-email-compboBox-field{
  padding-left: 10px;
  border-radius: 5px;
  width: 85%;
  height: 35px;
  background-color: #FFFFFF;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 25px;
  border-color: 10px solid white;
}


.signup-with-email-compboBox-field.k-state-invalid > .k-dropdown-wrap {
  border: 0px solid white;
}

.signup-with-email-compboBox-field .k-dropdown-wrap{
  border: 0px solid white;
}

