
.header-logo 
{
  background: #34394c;
  padding: 12px 0;
  margin: 0px;
  box-shadow: rgba(0,0,0,.156863) 0 3px 10px, rgba(0,0,0,.227451) 0 3px 10px;
  height: 63px;
  width: 55px;
  overflow: hidden;
}
.navbar-style{
  background-color: #3A4158;
   color: white; padding: 0px;
}
.navbar-content{
  color: white;
   font-size: 20px; float: left; line-height: 64px; margin-left: 15px;
}
.navbar-logout-link{
  position: absolute;
  float: right;
  z-index: 10;
  background-color: white;
  color:#003f4c;
  width:170px;
  height: 30px;
  padding-top: 5px;
  padding-bottom: 15px;
  text-align: left;
  text-decoration: none;
  font-size:13px;
  box-shadow: 1px 1px 1px 1px grey;
}
.user-icon-link{
    float:right;
   margin-right:50px; 
   margin-top:6px;
   z-index: 600;
   border-radius:45px;
   height:100%;
   font-size: 15px;
   color: white;
}
.user-icon{
  font-size: 27px;
  margin-right: 10px;
  color: white;
} 
.arrow-icon{
  font-size:25px;
  margin-left:10px;
}
.logout-icon{
  float:left; 
  font-size:13px;
  margin-left:10px;
  margin-top:2px;
  padding-right:20px;
  color: #003f4c;
}
.logout-button{
  float: right;
  margin-right: 195px;
   margin-top: -10px;
}
.reorder-icon{
  font-size: 27px;
  margin-left: 5px;
  color: #999;
}
.reorder-icon:hover{
  font-size: 27px;
  margin-left: 5px;
  color: #75AEF1;
}
.header-empty{
  padding: 2em;
}
