.role-main-div{
    background-color: white; box-sizing: border-box;
  }
  
  .role-main-plus-button{
    padding-bottom: 30px; padding-top: 20px; margin-left: 25px; font-size: 23px;
  }
  
  .role-main-Link-plus-button{
    float: right;
    margin-right: 40px;
    border-radius: 100px;
  }
  
  .role-alert-message{
    z-index: 5;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #3b4156;
    animation: cssAnimation 0s 1s forwards;
    padding: 10px;
    width: 25%;
    text-align: center;
    color: white;
  }
  .role-grid{
    background-color: white;
  }
  .role-buttons{
    display:flex;
  }

  .add-offer-icon {
    width: 1.2em;
    height: 1.2em;
}